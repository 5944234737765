<template>
  <div v-wechat-title="$route.meta.title"></div>
  <div class="background">
    <div class="container">
      <!-- 左侧模块 -->
      <div class="left">
        <!-- 新增表单输入 -->
        <div class="write_story">
          <el-input
            v-model="loveRecordFrom.matter"
            :rows="5"
            type="textarea"
            placeholder="今天有什么故事呢？快点写下来吧..."
            class="matter_input"
          />
          <Update
            @updateFrom="updatePictureInfo"
            ref="pictureUpload"
            v-if="isUpdate"
          ></Update>
          <div class="write_bottom">
            <div class="bottom_right">
              <el-icon
                :size="30"
                class="picIcon"
              >
                <Picture
                  v-if="!isUpdate"
                  @click="editUpdateState(true)"
                />
                <PictureFilled
                  v-if="isUpdate"
                  @click="editUpdateState(false)"
                />
              </el-icon>
              <span class="interval">｜</span>
              <span class="font-size-14">今日心情</span>
              <div style="display:flex">
                <i
                  :class="'iconfont icon-qingtian mood_icon ' + (loveRecordFrom.mood == 5 ? 'mood_icon_activate':'')"
                  @click="loveRecordFrom.mood = 5"
                ></i>
                <i
                  :class="'iconfont icon-duoyun mood_icon ' + (loveRecordFrom.mood == 4 ? 'mood_icon_activate':'')"
                  @click="loveRecordFrom.mood = 4"
                ></i>
                <i
                  :class="'iconfont icon-yintian mood_icon ' + (loveRecordFrom.mood == 3 ? 'mood_icon_activate':'')"
                  @click="loveRecordFrom.mood = 3"
                ></i>
                <i
                  :class="'iconfont icon-xiaoyu mood_icon ' + (loveRecordFrom.mood == 2 ? 'mood_icon_activate':'')"
                  @click="loveRecordFrom.mood = 2"
                ></i>
                <i
                  :class="'iconfont icon-leizhenyu mood_icon ' + (loveRecordFrom.mood == 1 ? 'mood_icon_activate':'')"
                  @click="loveRecordFrom.mood = 1"
                ></i>
              </div>
              <span class="interval">｜</span>
              <span class="font-size-14">对TA的评分</span>
              <el-rate
                class="rate"
                v-model="loveRecordFrom.rate"
                :texts="['很差劲', '不及格', '将将及格', '还差一点', '很不错']"
                show-text
                :colors="colors"
              />

            </div>
            <el-button
              type="primary"
              class="add_button"
              @click="insertLoveRecord()"
              :loading="add_loading"
            >
              <span style="vertical-align: middle"> 发布故事 </span>
            </el-button>
          </div>
        </div>
        <!-- 故事内容 -->
        <div class="story_info">
          <div
            v-for="(item, index) in loveRecordList"
            :key="item.id"
            class="love_record"
          >
            <div class="love_left">
              <div
                class="cricle2"
                v-if="index == 0"
              >
                <div class="infinity_text">&infin;</div>
              </div>

              <div
                class="cricle"
                v-else
              >
                <div class="cricle_text">{{item.create_time_toMath}}月</div>
              </div>
              <div
                class="line2"
                v-if="index == loveRecordList.length - 1"
              ></div>
              <div
                class="line"
                v-else
              ></div>
              <div
                class="cricle2"
                v-if="index == loveRecordList.length - 1"
              >
                X&amp;Y
              </div>
            </div>
            <div class="love_right">
              <div class="right_title">
                <div class="date">{{(item.create_time_toDate).split(' ')[0]}}</div>
                <div class="author">作者：{{item.user_nickname}}</div>
              </div>
              <hr class="love_hr" />
              <div class="right_info">
                <div class="info_bottom">
                  <div class="align_center">
                    <span class="font-size-14">对TA的评分&nbsp;</span>
                    <i
                      v-if="item.mood == 5"
                      class="iconfont icon-qingtian mood_icon_show"
                    ></i>
                    <i
                      v-if="item.mood == 4"
                      class="iconfont icon-duoyun mood_icon_show"
                    ></i>
                    <i
                      v-if="item.mood == 3"
                      class="iconfont icon-yintian mood_icon_show"
                    ></i>
                    <i
                      v-if="item.mood == 2"
                      class="iconfont icon-xiaoyu mood_icon_show"
                    ></i>
                    <i
                      v-if="item.mood == 1"
                      class="iconfont icon-leizhenyu mood_icon_show"
                    ></i>
                  </div>
                  <div class="align_center">
                    <span class="font-size-14">对TA的评分&nbsp;</span>
                    <el-rate
                      :model-value="item.rate"
                      :texts="['很差劲', '不及格', '将将及格', '还差一点', '很不错']"
                      show-text
                      :colors="colors"
                      disabled
                    />
                  </div>
                </div>
                <p class="matter">{{item.matter}}</p>
                <div>
                  <div v-if="item.picture != '' || item.picture != []">
                    <el-image
                      v-for="(img,index) in JSON.parse(item.picture)"
                      :key="index"
                      v-bind:src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' + img"
                      :preview-src-list="toJson(item.picture)"
                      class="info_image"
                      fit="contain"
                      :initial-index="index"
                    >
                      <template #placeholder>
                        <div class="image-slot">Loading<span class="dot">...</span></div>
                      </template>
                    </el-image>
                  </div>
                </div>
                <div class="address">{{item.create_time_toDate}}&nbsp;&nbsp;&nbsp;来自{{item.address_city}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧模块 -->
      <div class="right">
        <div style="position: sticky;top: 8px;">
          <div class="time_machine_mode">
            <div class="mode_title"><i
                class="fa fa-heart"
                style="color:red"
              ></i>&nbsp;时光机</div>
            <div
              v-for="(item, index) in timeMachineInfo"
              :key="item.id"
              class="time_machine_item"
            >
              <div class="time_machine_info">
                <div>
                  <div class="machine_info_title">{{item.time_type == 1?"已经":"距离"}}{{item.name}}</div>
                  <div class="machine_info_time">{{item.timestamp_toData}}</div>
                </div>
                <div class="count_down">
                  <span style="font-size:20px">{{item.countdown.day}}</span>
                  <span style="font-size:14px">{{item.countdown.other}}</span>
                </div>
              </div>
              <div
                class="divider_x"
                v-if="index !== timeMachineInfo.length-1"
              ></div>
            </div>
          </div>
          <div class="carousel_mode">
            <el-carousel
              height="197px"
              class="carousel"
            >
              <el-carousel-item>
                <img
                  src="https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/5625614E-B081-4DDD-A42E-896D598667DE.jpeg"
                  alt=""
                  class="carousel_img"
                >
              </el-carousel-item>
              <el-carousel-item>
                <img
                  src="https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/AD2B75C3-1603-4E7A-A443-6833A16F38D8.jpeg"
                  alt=""
                  class="carousel_img"
                >
              </el-carousel-item>
              <el-carousel-item>
                <img
                  src="https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/B6BDECBE-5D6C-4C72-AF97-0FFE5A2083EF.jpeg"
                  alt=""
                  class="carousel_img"
                >
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import Update from '../components/update.vue'
import { Picture, PictureFilled } from '@element-plus/icons-vue'
import {
  INSERTLOVERECORD,
  SELECTLOVERECORD,
  SELECTTIMEMACHINE
} from '../api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components: {
    Update,
    Picture,
    PictureFilled
  },
  setup() {
    onMounted(() => {
      selectLoveRecord()
      selectTimeMachine()
      getTIME()
    })
    const pictureUpload = ref(null)
    // 提交表单
    const loveRecordFrom = reactive({
      mood: '', // 心情
      rate: '', // 评分
      matter: '', // 内容
      picture: [] // 图片
    })

    // 评分颜色
    const colors = ref(['#99A9BF', '#F7BA2A', '#FF9900'])
    // 是否要上传图片
    const isUpdate = ref(false)
    // 修改图片上传状态
    const editUpdateState = (state) => {
      isUpdate.value = state
    }
    // 更新表单图片信息
    const updatePictureInfo = (pictureinfo) => {
      if (JSON.parse(pictureinfo).length === 0) {
        loveRecordFrom.picture = ''
      } else {
        loveRecordFrom.picture = pictureinfo
      }
    }
    // 新增按钮等待状态
    const add_loading = ref(false)
    // 新增
    const insertLoveRecord = () => {
      if (loveRecordFrom.matter === '') {
        ElMessage({
          showClose: true,
          message: '忘记写下今天的故事了！',
          type: 'warning'
        })
      } else if (loveRecordFrom.mood === '') {
        ElMessage({
          showClose: true,
          message: '忘记选择今日心情了！',
          type: 'warning'
        })
      } else if (loveRecordFrom.rate === 0) {
        ElMessage({
          showClose: true,
          message: '忘记给Ta评分了！',
          type: 'warning'
        })
      } else {
        ElMessageBox.confirm('确定全部都写好了，准备保存了吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '我再看看',
          type: 'success'
        })
          .then(() => {
            add_loading.value = true
            INSERTLOVERECORD(loveRecordFrom).then((response) => {
              if (response.code === -1) {
                add_loading.value = false
                ElMessage({
                  showClose: true,
                  message: response.msg,
                  type: 'warning'
                })
              } else if (response.code === 0) {
                add_loading.value = false
                // 清空表单
                loveRecordFrom.mood = ''
                loveRecordFrom.rate = ''
                loveRecordFrom.matter = ''
                loveRecordFrom.picture = []
                if (isUpdate) {
                  pictureUpload.value.clearPictureInfo()
                }
                loveRecordList.length = 0
                page.value = 1
                selectLoveRecord()
                ElMessage({
                  showClose: true,
                  message: response.msg,
                  type: 'success'
                })
              }
            })
          })
          .catch(() => {
            // catch error
          })
      }
    }
    // 爱情记录信息
    const loveRecordList = reactive([])
    // 页码
    const page = ref(1)
    // 每次加载条数
    const pageSize = ref(2)
    // 是否禁止查询
    const scrollDisable = ref(false)
    // 查询加载状态
    const loading = ref(false)
    // 查询
    const selectLoveRecord = () => {
      loading.value = true
      SELECTLOVERECORD({ page: page.value, pageSize: pageSize.value }).then(
        (response) => {
          if (response.code === 0) {
            if (response.data.data.length === 0) {
              scrollDisable.value = true
            } else {
              response.data.data.forEach((element) => {
                loveRecordList.push(element)
              })
              page.value = page.value + 1
            }
            loading.value = false
          }
        }
      )
    }
    // 图片列表解析json字符串
    const toJson = (val) => {
      var obj = JSON.parse(val)
      var obj1 = []
      if (obj.length > 0) {
        for (var i = 0; i < obj.length; i++) {
          obj1.push(
            'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
              obj[i]
          )
        }
      }
      return obj1
    }
    // 时光机信息
    const timeMachineInfo = ref([])
    // 查询时光机
    const selectTimeMachine = () => {
      SELECTTIMEMACHINE().then((response) => {
        if (response.code === 0) {
          timeMachineInfo.value = response.data
        }
      })
    }

    const TIME = () => {
      if (timeMachineInfo.value !== []) {
        timeMachineInfo.value.forEach((x) => {
          if (x.time_type === 1) {
            x.countdown = getDuration(
              parseInt(new Date().getTime() / 1000) - Number(x.timestamp)
            )
          } else {
            x.countdown = getDuration(
              Number(x.timestamp) - parseInt(new Date().getTime() / 1000)
            )
          }
        })
      }
    }

    function getDuration(second) {
      var days = Math.floor(second / 86400)
      var hours = Math.floor((second % 86400) / 3600)
      var minutes = Math.floor(((second % 86400) % 3600) / 60)
      var seconds = Math.floor(((second % 86400) % 3600) % 60)
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }

      return {
        day: days + '天',
        other: hours + '小时' + minutes + '分' + seconds + '秒'
      }
    }

    function getTIME() {
      setInterval(TIME, 500)
    }

    return {
      pictureUpload,
      loveRecordFrom,
      colors,
      isUpdate,
      editUpdateState,
      updatePictureInfo,
      loveRecordList,
      add_loading,
      insertLoveRecord,
      scrollDisable,
      loading,
      toJson,
      timeMachineInfo
    }
  }
}
</script>

<style>
.matter_input .el-textarea__inner {
  border-radius: 5px;
}
</style>
<style src="../css/loveRecord.css" scoped>
</style>
<style src="../css/icon/iconfont.css">
</style>
